/***************
メインカラー：#000000
****************/
$def-letter-spc: -0.02em;
$color-midashi: #B66C84;
$color-body: #222222;
$color-inner-link: #FF006C;
$color-accent: #C691B1;
$common-width: 1080px;

body {
  margin: 0;
  background-color: #FFF;
  font-family: a-otf-gothic-bbb-pr6n,sans-serif;
  font-weight: 400;
  font-style: normal;
}
a {
  text-decoration: none;
}
input:-webkit-autofill {
  animation-name: onAutoFillStart;
  transition: background-color 50000s ease-in-out 0s;
}
/* iOSでのデフォルトスタイルをリセット */
input[type="submit"],
input[type="button"] {
  border-radius: 0;
  -webkit-box-sizing: content-box;
  -webkit-appearance: button;
  appearance: button;
  border: none;
  box-sizing: border-box;
  cursor: pointer;
}
input[type="submit"]::-webkit-search-decoration,
input[type="button"]::-webkit-search-decoration {
  display: none;
}
input[type="submit"]::focus,
input[type="button"]::focus {
  outline-offset: -2px;
}
input,select,textarea{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: a-otf-gothic-bbb-pr6n,sans-serif;
  font-weight: 400;
  font-style: normal;
  outline: none;
  border-radius: 0;
  border: 0;
  background: none;
}
.pc-only {
  display: none!important;
}
.sp-only {
  display: block;
}
.common-hover-opacity:hover {
  opacity: 0.8;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
p {
  margin: 0;
  padding: 0;
}
.clear{
  clear: both;
}
.clearfix {
  clear: both;
}
.common-margin{
    height: 20px;
}
.common-margin.small{
    height: 10px;
}
.common-margin.medium{
    height: 30px;
}
.common-margin.large{
    height: 50px;
}
h1,h2,h3,h4,h5 {
  margin: 0;
  padding: 0;
  font-family: a-otf-midashi-go-mb31-pr6n,sans-serif;
  font-weight: 600;
  font-style: normal;
}
ul, li {
  margin: 0;
  padding: 0;
}
ol, ul {
  list-style: none;
}
*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

/* フォント類 */
.font-common {
  font-family: a-otf-gothic-bbb-pr6n,sans-serif;
}
.font-a-otf-midashi-go {
  font-family: a-otf-midashi-go-mb31-pr6n,sans-serif;
  font-weight: 600;
  font-style: normal;
}
.font-a-otf-midashi-mi {
  font-family: a-otf-midashi-mi-ma31-pr6n,serif;
  font-weight: 600;
  font-style: normal;
}
@font-face {
	font-family: 'HomepageBaukasten';
  src: url('/wp-content/themes/genius/assets/font/HomepageBaukasten-Bold.eot');
  src:
	     url('/wp-content/themes/genius/assets/font/HomepageBaukasten-Bold.woff') format('woff'),
	     url('/wp-content/themes/genius/assets/font/HomepageBaukasten-Bold.ttf')  format('truetype')
}
@font-face {
	font-family: 'HomepageBaukasten-Book';
  src: url('/wp-content/themes/genius/assets/font/HomepageBaukasten-Book.eot');
  src:
	     url('/wp-content/themes/genius/assets/font/HomepageBaukasten-Book.woff') format('woff'),
	     url('/wp-content/themes/genius/assets/font/HomepageBaukasten-Book.ttf')  format('truetype')
}
@font-face {
	font-family: 'Hoefler Text';
  src: url('/wp-content/themes/genius/assets/font/HoeflerTextRegular.eot');
  src:
	     url('/wp-content/themes/genius/assets/font/HoeflerTextRegular.woff') format('woff'),
	     url('/wp-content/themes/genius/assets/font/HoeflerTextRegular.ttf')  format('truetype')
}
.font-homepage-aukasten {
  font-family: "HomepageBaukasten", sans-serif !important;
}
.font-homepage-aukasten-book {
  font-family: "HomepageBaukasten-Book", sans-serif !important;
}
.font-hoefler {
  font-family: "Hoefler Text";
}

/**********
共通アニメーション
************/
.fadein {
  opacity: 0;
  transform : translate(0, 30px);
  transition : all 800ms;

  &.scrollin{
    opacity: 1;
    transform: translate(0, 0);
  }//.scrollin
}//.fadein

.fadein-side {
  opacity: 0;
  transform : translate(-20px, 0px);
  transition : all 800ms;

  &.scrollin{
    opacity: 1;
    transform: translate(0, 0);
  }//.scrollin
}//.fadein

.fadein-delay {
  opacity: 0;
  transition : all 1200ms;
  transition-delay: 0.5s;

  &.scrollin-delay{
    opacity: 1;
  }//.scrollin
}//.fadein


.common-hover {
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
}//.common-hover

.appeal-button {
  color: #1C1C1C;
  font-size: 14px;
  text-align: center;
  height: 52px;
  line-height: 52px;
  border-radius: 40px;
  background: rgb(255,255,255);
  background: -moz-linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(229,242,229,1) 0%, rgba(154,227,217,1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(229,242,229,1) 0%, rgba(154,227,217,1) 100%);
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(229,242,229,1) 0%, rgba(154,227,217,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#9ae3d9",GradientType=1);
  position: relative;

  a {
    color: #1C1C1C;
    position: absolute;
    top: 0;
    left: 0;
    height:100%;
    width: 100%;
  }
}//.appeal-button

.common-button {
  color: $color-accent;
  font-size: 14px;
  text-align: center;
  height: 52px;
  line-height: 48px;
  border-radius: 40px;
  border: 2px solid $color-accent;
  position: relative;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;

  a {
    color: $color-accent;
    position: absolute;
    top: 0;
    left: 0;
    height:100%;
    width: 100%;
  }

  &:hover {
    background-color: $color-accent;
    color: #FFF;
  }
}//.common-button


header {
  width: 100%;
  height: 60px;
  background-color: #FFF;
  padding: 0 18px;
  // position: absolute;
  z-index: 50;

  .header-wrapper {

    .logo {
      width: 130px;
      float: left;
      margin-top: 15px;
      img {
        width: 100%;
        margin-top: 1px;
        margin-left: -2px;
      }
    }//.logo

    .contact-wrapper {
      width: 60px;
      float: right;

    }//.contact-wrapper
  }//.header-wrapper

  #icon-menu {
    position: absolute;
    right: 8px;
    width: 40px;
    height: 60px;
    text-align: center;
    img {
      width: 16px;
      margin-top: 26px;
    }
  }//#icon-menu

  #sp-header-menu {
    width: 100%;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.09);
    opacity: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;

    &.menu-open{
      opacity: 1;
      z-index: 100;
    }

    .wrapper {
      padding: 14px 16px 40px 16px;
      position: relative;

      #close-button {
        width: 40px;
        height: 60px;
        position: absolute;
        top: 0px;
        right: 0px;

        img {
          width: 12px;
          margin-left: 5px;
          margin-top: 21px;
        }
      }//#close-button

      .logo {
        width: 130px;
        float: left;
        margin-top: 2px;

        img {
          width: 100%;
        }
      }
      .close-button {
        // position: absolute;
          float: right;
      }
      ul{
        padding-top: 35px;

        li{
          text-align: center;
          height:33px;
          font-size: 14px;

          a {
            color: #000000;
          }

          &.toppage {
            a {
              color: #755391;
            }
          }//.toppage
        }//li
      }//ul

      .sns-box {
        text-align: center;
        margin-top: 20px;

        a{
          color: #000000;
          .fa-facebook-f {
            font-size: 16px;
            margin-right: 15px;
          }
          .fa-instagram {
            font-size: 18px;
          }
        }//a
      }//.sns-box
    }//.wrapper
  }//#sp-header-menu

}//#header

.fv-wrapper {
  position: relative;
  width: 100%;
  height: 175px;
  background-image: url("/wp-content/themes/genius/assets/img/sp/bg-header.png");
  background-position: top 0px center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 35px;
  z-index: 0;

  .inner-wrapper {
    padding: 0 25px;
  }

  .breadcrumb {
    font-size: 11px;
    text-align: center;
    margin-bottom: 40px;
    color: #363636;

    li {
      display: inline-block;
      a {
        color: #363636;
      }
    }
  }//.breadcrumb

  .main-title {
    font-size: 20px;
    letter-spacing: 0.11em;
    color: #000000;
    margin-bottom: 3px;
    text-align: center;
  }

  .mask {
    position: absolute;
    top: -25px;
    left: 0;
    width: 100%;
    height: 200px;
    background: rgb(255,255,255);
    background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
    z-index: -1;
  }
}
.main-wrapper{
  z-index: 1;
  position: relative;
}
/******************
読み込みインジケーター
*******************/
#initial-mask{
  background: rgb(248,231,241);
  background: -moz-linear-gradient(180deg, rgba(248,231,241,1) 0%, rgba(244,250,246,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(248,231,241,1) 0%, rgba(244,250,246,1) 100%);
  background: linear-gradient(180deg, rgba(248,231,241,1) 0%, rgba(244,250,246,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f8e7f1",endColorstr="#f4faf6",GradientType=1);
  width: 100%;
  height: 1000px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  text-align: center;
  .wrapper{
    margin: 0 auto;
    width: 130px;
    height: 160px;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 100%;
    margin-top: 220px;
    background-image: url('/wp-content/themes/genius/assets/img/common/logo-v-l.png');
  }
}
footer {
  width: 100%;
  background-color: #F8F7F7;
  padding: 30px 20px 40px 20px;

  .footer-wrapper {
    margin: 0 auto;
  }

  .logo {
    width: 100%;
    margin-top: 15px;
    margin-bottom: 22px;
    text-align: center;
    img {
      width: 88px;
    }
  }//.logo

  .sns-box {
    width: 50px;
    margin: 0 auto;
    .fa-facebook-f {
      font-size: 20px;
      margin-right: 15px;
      color: #414141;
    }
    .fa-instagram {
      font-size: 22px;
      color: #414141;
      position: absolute;
      margin-top: 1px;
      margin-left: 10px;
    }
  }//.contact-wrapper

  .footer-wrapper {
    &.under {
      color: #8B7B7B;
      margin-top: 30px;

      .left {
        font-size: 10px;
        width: 100%;
        text-align: center;
      }//.left
    }//.under
  }//.footer-wrapper

}//#footer

